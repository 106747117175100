<template>
  <dialog id="dialog" class="overflow-y-hidden sm:overflow-x-hidden">
    <form v-if="getLayoutType != 'ticket'" method="dialog" class="text-right w-full md:hidden">
      <button class="close" @click="clearData()"><img src="/img/icons/close.svg" alt=""></button>
    </form>
    <div v-if="getLayoutType != 'ticket'" class="p-8 sm:p-0 sm:mr-4 sm:ml-4 h-full">
      <div class="flex-wrap h-full ">
        <div v-if="getLayoutType == 'talent' || getLayoutType == 'other'" class="w-full flex sm:flex-wrap">
          <div class="text-[3rem] sm:text-[2.8rem] font-semibold pt-2 flex-auto sm:leading-[3rem] break-words hyphens-auto sm:w-full">{{ state.name.value }}</div>
          <form method="dialog" class="text-right w-8 sm:hidden">
            <button class="close" @click="clearData()"><img src="/img/icons/close.svg" alt=""></button>
          </form>
        </div>

        <div v-if="getLayoutType == 'product'" class="w-full flex sm:flex-wrap">
          <div class="md:hidden text-[3rem] sm:text-[2.8rem] font-semibold pt-2 flex-auto sm:leading-[3rem] break-words hyphens-auto sm:w-full">{{ state.name.value }}</div>
          <form method="dialog" class="w-full text-right sm:hidden">
            <button class="close" @click="clearData()"><img src="/img/icons/close.svg" alt=""></button>
          </form>
        </div>

        <div class="flex sm:flex-col md:h-full sm:h-[100%] md:overflow-y-hidden sm:overflow-y-scroll overflow-x-hidden no-scrollbar">

          <!--          Talent Layout-->
          <div v-if="getLayoutType == 'talent'" class="pt-6 w-[41.75rem] sm:w-full sm:h-fit md:overflow-y-auto">
            <div v-if="!state.video.value" class="md:rounded-[0.75rem] h-[23.5rem] sm:h-[9.6875rem] bg-white pb-8">
              <img :src="image1 + '?key=webp'" alt="" class="w-full">
            </div>
            <iframe ref="talentvideo" v-if="state.video.value" class="w-full md:rounded-[0.75rem] h-[23.5rem] sm:h-[9.6875rem] border-0 sm:w-auto"
                    :src="'https://www.youtube.com/embed/' + state.video.value">
            </iframe>
            <div v-if="getLayoutType == 'talent'" class="w-full text-[1.125rem] mb-32 mt-8 leading-[1.8rem] font-semibold sm:hidden">
              {{state.description.value}}
            </div>
          </div>

          <div v-if="getLayoutType == 'talent'" class="pt-4 sm:pt-6 ml-10 sm:ml-0 sm:w-full flex-wrap">
            <div class="text-[2rem] sm:text-[1.5rem] font-semibold">Schedules</div>
            <div class="mb-28 sm:mb-8">
              <div v-if="state.location.value" class="text-[1.5rem] sm:text-[1.125rem] font-semibold place-items-center flex gap-2 mt-6 sm:mt-4">
                <img src="/img/icons/location_black.svg" alt="" class="w-[2rem] h-[2rem] sm:w-[1.5rem] sm:h-[1.5rem] sm:ml-0" />
                {{ state.location.value }}
              </div>
              <div v-if="state.time.value" class="text-[1.5rem] sm:text-[1.125rem] font-semibold place-items-center flex gap-2 mt-6 sm:mt-4">
                <img src="/img/icons/time_black.svg" alt="" class="w-[2rem] h-[2rem] sm:w-[1.5rem] sm:h-[1.5rem] sm:ml-0" />
                {{ state.time.value }}
              </div>
            </div>
            <div class="text-[2rem] sm:text-[1.5rem] font-semibold">Social Media</div>
            <div class="flex gap-2.5 pt-6 sm:pt-4">
              <a v-if="state.instagram.value" :href="state.instagram.value" target="_blank" class="h-[2rem]">
                <img src="/img/icons/instagram_black.svg" alt="" class="w-[2rem] h-[2rem] sm:h-[1.5rem] sm:w-[1.5rem] sm:ml-0" />
              </a>
              <a v-if="state.facebook.value" :href="state.facebook.value" target="_blank" class="h-[2rem]">
                <img src="/img/icons/facebook_black.svg" alt="" class="ml-2 w-[2rem] h-[2rem] sm:h-[1.5rem] sm:w-[1.5rem]" />
              </a>
              <a v-if="state.youtube.value" :href="state.youtube.value" target="_blank" class="h-[2rem]">
                <img src="/img/icons/youtube_black.svg" alt="" class="ml-2 w-[2rem] h-[2rem] sm:h-[1.5rem] sm:w-[1.5rem]" />
              </a>
              <a v-if="state.twitter.value" :href="state.twitter.value" target="_blank" class="h-[2rem]">
                <img src="/img/icons/twitter_black.svg" alt="" class="ml-2 w-[2rem] h-[2rem] sm:h-[1.5rem] sm:w-[1.5rem]" />
              </a>
              <a v-if="state.website.value" :href="state.website.value" target="_blank" class="h-[2rem]">
                <img src="/img/icons/website.svg" alt="" class="ml-2 w-[2rem] h-[2rem] sm:h-[1.5rem] sm:w-[1.5rem]" />
              </a>
            </div>
            <div class="w-full text-[1.125rem] mb-48 mt-6 leading-[1.8rem] font-semibold md:hidden overflow-y-scroll">
              {{state.description.value}}
            </div>
          </div>

<!--        Other layout-->
          <div v-if="getLayoutType == 'other'" class="pt-6 w-[30rem] min-w-[30rem] sm:w-full sm:min-w-full sm:h-fit">
            <div v-if="!state.video.value" class="md:rounded-[0.75rem] w-full h-[18.75rem] sm:h-[13.75rem] bg-white box-border flex">
              <img :src="image1 + '?key=webp'" alt="" class="w-full object-contain">
            </div>
            <iframe v-if="state.video.value" class="w-full md:rounded-[0.75rem] h-[18.75rem] sm:h-[13.75rem] sm:w-auto"
                    :src="'https://www.youtube.com/embed/' + state.video.value">
            </iframe>
<!--            <div v-if="getLayoutType == 'talent'" class="w-full text-[1.125rem] mb-32 mt-8 leading-[1.8rem] font-semibold sm:hidden">-->
<!--              {{state.description.value}}-->
<!--            </div>-->
          </div>

          <div v-if="getLayoutType == 'other'" class="pt-4 sm:pt-4 ml-10 sm:ml-0 sm:w-full flex-wrap overflow-y-auto">
            <div class="flex gap-2.5 pt-2 sm:pt-4">
              <a v-if="state.instagram.value" :href="state.instagram.value" target="_blank" class="h-[2rem] mb-6">
                <img src="/img/icons/instagram_black.svg" alt="" class="w-[2rem] h-[2rem] sm:h-[1.5rem] sm:w-[1.5rem]" />
              </a>
              <a v-if="state.facebook.value" :href="state.facebook.value" target="_blank" class="h-[2rem] mb-6">
                <img src="/img/icons/facebook_black.svg" alt="" class="ml-2 w-[2rem] h-[2rem] sm:h-[1.5rem] sm:w-[1.5rem]" />
              </a>
              <a v-if="state.youtube.value" :href="state.youtube.value" target="_blank" class="h-[2rem] mb-6">
                <img src="/img/icons/youtube_black.svg" alt="" class="ml-2 w-[2rem] h-[2rem] sm:h-
                [1.5rem] sm:w-[1.5rem]" />
              </a>
              <a v-if="state.twitter.value" :href="state.twitter.value" target="_blank" class="h-[2rem] mb-6">
                <img src="/img/icons/twitter_black.svg" alt="" class="ml-2 w-[2rem] h-[2rem] sm:h-[1.5rem] sm:w-[1.5rem]" />
              </a>
              <a v-if="state.website.value" :href="state.website.value" target="_blank" class="h-[2rem] mb-6">
                <img src="/img/icons/website.svg" alt="" class="ml-2 w-[2rem] h-[2rem] sm:h-[1.5rem] sm:w-[1.5rem]" />
              </a>
            </div>
            <div class="text-[1.125rem] mb-60 leading-[1.8rem] font-semibold overflow-y-auto break-words hyphens-auto">
              {{state.description.value}}
            </div>
          </div>

          <!-- Product layout-->
          <div v-if="getLayoutType == 'product'" class="sm:hidden flex flex-col w-[130px] min-w-[130px] md:overflow-y-scroll gap-2 md:mt-3 ">
            <div v-if="image1" class="md:rounded-[0.75rem] h-[130px] min-w-[130px] bg-white cursor-pointer" @click="setMainImage(0)">
              <img :src="image1 + '?key=webp'" alt="" class="md:rounded-[0.75rem] w-full h-full object-cover">
            </div>
            <div v-if="image2" class="md:rounded-[0.75rem] h-[130px] min-w-[130px] bg-white cursor-pointer" @click="setMainImage(1)">
              <img :src="image2 + '?key=webp'" alt="" class="md:rounded-[0.75rem] w-full h-full object-cover">
            </div>
            <div v-if="image3" class="md:rounded-[0.75rem] h-[130px] min-w-[130px] bg-white cursor-pointer" @click="setMainImage(2)">
              <img :src="image3 + '?key=webp'" alt="" class="md:rounded-[0.75rem] w-full h-full object-cover">
            </div>
            <div v-if="image4" class="md:rounded-[0.75rem] h-[130px] min-w-[130px] bg-white cursor-pointer" @click="setMainImage(3)">
              <img :src="image4 + '?key=webp'" alt="" class="md:rounded-[0.75rem] w-full h-full object-cover">
            </div>
          </div>

          <div v-if="getLayoutType == 'product'" class="sm:hidden w-[32.5rem] min-w-[32.5rem] sm:w-full sm:h-fit md:overflow-y-scroll mt-3 mx-5">
            <div v-if="!state.video.value" class="md:rounded-[0.75rem] h-[32.5rem] sm:h-[9.6875rem] bg-white">
              <img :src="image1 + '?key=webp'" alt="" class="md:rounded-[0.75rem] w-full h-full object-cover">
            </div>
          </div>

          <div v-if="getLayoutType == 'product'" class="sm:pt-6 ml-4 sm:ml-0 sm:w-full flex overflow-y-scroll md:h-[33.1875rem] no-scrollbar flex-col">
            <div class="text-[3rem] font-semibold sm:hidden">{{ state.name.value }}</div>
            <div class="mb-4 sm:mb-2">
              <div class="flex h-[15.625rem] min-h-[15.625rem] overflow-x-scroll overflow-y-hidden gap-4 md:hidden">
                <div v-if="image1" class="rounded-[0.75rem] h-[15.625rem] w-[15.625rem] min-w-[15.625rem] bg-white">
                  <img :src="image1 + '?key=webp'" alt="" class="rounded-[0.75rem] w-full h-full object-cover">
                </div>
                <div v-if="image2" class="rounded-[0.75rem] h-[15.625rem] min-w-[15.625rem] bg-white">
                  <img :src="image2 + '?key=webp'" alt="" class="rounded-[0.75rem] w-full h-full object-cover">
                </div>
                <div v-if="image3" class="rounded-[0.75rem] h-[15.625rem] min-w-[15.625rem] bg-white">
                  <img :src="image3 + '?key=webp'" alt="" class="rounded-[0.75rem] w-full h-full object-cover">
                </div>
                <div v-if="image4" class="rounded-[0.75rem] h-[15.625rem] min-w-[15.625rem] bg-white">
                  <img :src="image4 + '?key=webp'" alt="" class="rounded-[0.75rem] w-full h-full object-cover">
                </div>
              </div>
              <div class="text-[2rem] font-semibold place-items-center sm:place-content-center flex gap-2 mt-6 sm:mt-4  break-words hyphens-auto">
                {{ currency(state.price.value) }}
              </div>
              <a :href="state.productLink.value" class="h-[3.5rem] text-[1.125rem] sm:text-[1rem] font-semibold place-items-center flex mt-6 sm:mt-4 rounded-[6px] bg-primary place-content-center">
                Buy Now
              </a>
              <div class="text-[1.125rem] sm:text-[1rem] font-semibold place-items-center flex gap-2 mt-6 sm:mt-4 sm:hidden break-words hyphens-auto" v-html="state.description.value"></div>
            </div>
            <div class="w-full text-[1.125rem] mb-40 mt-6 leading-[1.8rem] font-semibold md:hidden" v-html="state.description.value"></div>
          </div>
        </div>
      </div>
    </div>

    <!--  Ticket layout-->
    <div v-if="getLayoutType == 'ticket'" class="flex ml-[-1rem] mt-[-1rem] box-border w-full sm:w-[22.95rem]">
        <div class="flex w-full h-full sm:flex-col">
            <div class="w-[22.875rem] min-w-[22.875rem] sm:w-full sm:min-w-full sm:h-[17.5rem]">
                <div v-if="!state.video.value" class="w-full h-[30.1875rem] sm:h-[17.5rem] bg-white
                box-border flex bg-cover bg-center"
                     :style="{'background-image': 'url(' + image1 + ')'}"
                >
                  <div class="backdrop-brightness-[60%] flex justify-center items-center w-full h-full">
                    <form method="dialog" class="w-full text-right md:hidden h-[1rem] top-4 right-2 absolute">
                      <button class="close" @click="clearData()"><img src="/img/icons/close.svg" alt=""></button>
                    </form>
                    <img :src="image2 + '?key=webp'" alt="" class="w-[220px] h-[220px] sm:w-[120px] sm:h-[120px] object-contain ">
                  </div>
                </div>
<!--                <div v-if="getLayoutType == 'ticket'" class="w-full text-[1.125rem] mb-32 mt-8 leading-[1.8rem] font-semibold sm:hidden">-->
<!--                    {{state.description.value}}-->
<!--                </div>-->
            </div>

            <div class="w-full flex flex-col ml-10 sm:ml-0 sm:box-border sm:px-6">
              <form method="dialog" class="w-full text-right sm:hidden pt-6">
                <button class="close" @click="clearData()"><img src="/img/icons/close.svg" alt=""></button>
              </form>
              <div class="font-semibold text-[3rem] sm:text-[2rem] sm:pt-6">
                {{state.name.value}}
              </div>
              <div class="font-semibold text-[2rem] sm:text-[1.25rem] mt-4">
                {{state.description.value}}
              </div>

              <div class="w-full flex mt-24 sm:mt-6 sm:flex-col sm:items-center">
                <input v-model="email" class="rounded-l-[3.125rem] sm:rounded-[3.125rem] h-[4.4375rem] sm:h-[3.375rem]
                border-0 md:pl-8 text-[1.5rem] sm:text-[1rem] font-semibold text-black sm:text-center w-full py-0"
                        type="text" placeholder="Email Address"
                        required style="box-shadow: none; font-family: 'Baskerville', sans-serif">
                <button class="flex min-w-[12rem] sm:min-w-[10rem] sm:w-[10rem] rounded-r-[3.125rem] sm:rounded-[3.125rem] h-[4.4375rem] sm:h-[3.375rem] text-[1.5rem]
                font-semibold bg-black items-center text-white justify-center sm:text-[1rem] sm:mt-4 filter disabled:brightness-50"
                        style="box-shadow: none; font-family: 'Baskerville', sans-serif"
                        :disabled="!emailValid"
                  @click="subscribe"
                >Subscribe</button>
              </div>
              <div v-if="subscribeSuccess == true" class="w-full flex mt-4 sm:mt-1 sm:flex-col place-content-center items-center text-center">Sukses subscribe ke newsletter KEUKEN!</div>
              <div v-if="subscribeSuccess == false" class="w-full flex mt-4 sm:mt-1 sm:flex-col place-content-center items-center text-center">Email telah terdaftar di newsletter KEUKEN!</div>
            </div>
        </div>
    </div>

  </dialog>
</template>


<script setup lang="ts">
import {onMounted, ref, watch, computed} from "vue";
import {PUBLIC_API_URL, useGlobalState} from '../utils/store'
import {isMobile, isXxl} from "../utils/mobile";
import {currency} from "../utils/numbers";

const url = import.meta.env.PUBLIC_API_URL;
const mainImage = ref("");
const state = useGlobalState()
const image1 = ref("")
const image2 = ref("")
const image3 = ref("")
const image4 = ref("")
const talentvideo = ref();
const email = ref("");
const subscribeSuccess = ref();

watch(state.isOpen, () => {
  if (state.isOpen.value == true) {
    document.getElementsByTagName("body")[0].style = "overflow:hidden;"
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }
})

watch(state.images, () => {
  image1.value = `${url}/assets/${state.images.value?.[0]?.directus_files_id}`
  if (state.images.value?.[1]?.directus_files_id) {
    image2.value = `${url}/assets/${state.images.value?.[1]?.directus_files_id}`
  }
  if (state.images.value?.[2]?.directus_files_id) {
    image3.value = `${url}/assets/${state.images.value?.[1]?.directus_files_id}`
  }
  if (state.images.value?.[3]?.directus_files_id) {
    image4.value = `${url}/assets/${state.images.value?.[1]?.directus_files_id}`
  }
})

onMounted(() => {
  document.getElementById("dialog").addEventListener('click', function (event) {
    var rect = this.getBoundingClientRect();
    var isInDialog=(rect.top <= event.clientY && event.clientY <= rect.top + rect.height
      && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
    if (!isInDialog) {
      clearData();
      this.close();
    }
  });
})

const clearData = () => {
  state.images.value = [];
  state.isOpen.value = false;
  state.time.value = '';
  state.location.value = '';
  state.video.value = '';
  state.facebook.value = '';
  state.instagram.value = '';
  state.facebook.value = '';
  state.youtube.value = '';
  state.website.value = '';
  email.value = '';
  image1.value = "";
  image2.value = "";
  image3.value = "";
  image4.value = "";
  subscribeSuccess.value = null;
  if (talentvideo.value) {
    talentvideo.value.src = '';
  }
}

const emailValid = computed(() => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
    return true;
  } else {
    return false;
  }
})

const subscribe = async () => {
  try {
    const response = await fetch(`${PUBLIC_API_URL}/Items/Subscribers`, {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        email: email.value,
      }),
    });
    subscribeSuccess.value = response.status == 204;
    email.value = ""
  } catch(e) {
    throw e
  }
}

const setMainImage = (idx: number) => {
  mainImage.value = state.images.value[idx].directus_files_id;
}

const getLayoutType = computed(() => {
  if (state.type.value == "product") {
    return "product";
  } else {
    if (state.type.value == "talent") {
      return "talent";
    } if (state.type.value == "ticket") {
          return "ticket";
      } else {
      return "other";
    }
  }
})

const getLayoutHeight = computed(() => {
  switch (getLayoutType.value) {
    case "product":
      return 42.875;
    case "other":
      return 32.1875;
    case "talent":
      return 43.75;
    case "ticket":
        return 28.125;
  }
})

const getMobileHeight = computed(() => {
  switch (getLayoutType.value) {
    case "ticket":
      return 40;
    default:
      return 53.5625;
  }
})


</script>

<style lang="scss">
dialog {
  --height: calc(v-bind('getLayoutHeight') * 1rem);
  --m-height: calc(v-bind('getMobileHeight') * 1rem);
  @apply w-[66.875rem] h-[var(--height)] bg-white sm:w-[20.9375rem] sm:h-[var(--m-height)];
  position: fixed;
  //border-radius: 1.875rem;
  border: none;
  &::backdrop {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.75);
  }
}

.close {
  @apply text-3xl;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
